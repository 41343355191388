<!-- 新版弹窗  -->
<template>
  <div v-if="mid_ad && mid_obj.pic_url && mid_obj.pic_url.length > 0" class="container">
    <div class="content">
      <div class="img_box">
        <img :src="mid_obj.pic_url" @click="goActive(mid_obj.land_url,2)">
      </div>
      <div class="close">
        <img src="https://z3.ax1x.com/2021/11/05/Iub7Y4.png" alt="" @click="mid_ad = false">
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import tools from '@/utils/tools.js'
import { getInventory } from '@/api/shouye'
import tool from '@/utils/tools.js'

export default {
  props: {
    adId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isShow: false,
      imgU: '',
      link: '',
      // 广告位id，固定
      mid_ad: false,
      mid_obj: {}
    }
  },
  computed: {},
  created() {
    this.getNewAlertFunc()
  },
  mounted() { },
  // 销毁监听
  destroyed() { },
  methods: {
    // 获取弹窗信息
    getNewAlertFunc() {
      getInventory({ type: this.adId }).then(res => {
        console.log(res)
        if (res.errNo === 0) {
          const result = res.result || {}
          const center = result.center || []
          // const right = result.right || []
          // const top = result.top || []
          // this.gettedTopAds(top)
          this.gettedCenterAds(center)
          // this.gettedRightAds(right)
        }
      })
    },
    // 获取到中间接口数据
    gettedCenterAds(center) {
      console.log(center)
      // 判断中间是否 可展示，有其他条件可在此添加
      if (center && center.length > 0) {
        this.mid_obj = center[0]
        // this.mid_ad = true
        this.getShowMiddleRules()
      } else {
        this.mid_obj = {}
        this.mid_ad = false
      }
    },
    // 中间广告判断是否展示
    getShowMiddleRules() {
      // 当前时间的时间戳
      const now = new Date().getTime()
      // 当天0点的时间戳
      const newDate = tools.get_date('yyyy-mm-dd') + ' 00:00:00'
      const newDateStr = new Date(newDate).getTime()
      // 如果首次进入页面
      if (!localStorage.getItem('popads_h5_mid_time_' + Cookies.get('uid'))) {
        this.mid_ad = true
        localStorage.setItem('popads_h5_mid_time_' + Cookies.get('uid'), now)
      } else {
        // 再次进入页面
        const last = localStorage.getItem('popads_h5_mid_time_' + Cookies.get('uid'))// 最后一次浏览时间
        // 如果当天0点时间<最后一次次浏览时间，不再出弹窗并且存入最新浏览时间戳
        if (newDateStr < last) {
          localStorage.setItem('popads_h5_mid_time_' + Cookies.get('uid'), last)
        } else { // 否则弹窗显示，存入浏览时间的时间戳
          this.mid_ad = true
          localStorage.setItem('popads_h5_mid_time_' + Cookies.get('uid'), now)
        }
      }
    },
    // 点击事件
    goActive(url, pos) {
      console.log(url, pos)
      if (url && url.length > 0) {
        if (tool.isInApp()) {
          if (tool.isInIosApp()) {
            window.webkit.messageHandlers.vue_openUrl.postMessage(url)
          } else {
            this.$jsbridge.callHandler(
              'vue_openUrl'
              , url
              , function(responseData) {
                // android 返参数接受
              }
            )
          }
        } else {
          window.open(url)
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    .img_box {
      width: 670px;
      img {
        display: block;
        width: 100%;
        cursor: pointer;
      }
    }
    .close {
      width: 60px;
      margin: 0 auto;
      margin-top: 10px;
      img {
        width: 100%;
        display: block;
        cursor: pointer;
      }
    }
  }
}
</style>
