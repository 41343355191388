<template>
  <div :class="props ? 'sb' : 's'">
    <div v-if="show" class="top">
      <div class="top_left" @click="prop">
        <!-- <img class="flimg" :src="thumb" alt> -->
        <img class="flimg" src="https://newoss.zhulong.com/forum/202206/02/32/10063260xgyiqxspwywnic.png" alt="">
        <div class="class_name">
          {{ name }}
        </div>
        <img class="jt" src="@/assets/jiantou3.gif" alt>
      </div>
      <div class="top_middle" @click="goSearch">
        <img src="@/assets/ss2.png" alt>
        <span>搜索</span>
      </div>
      <div class="top_right" @click="toNews">
        <img src="@/assets/xiaoxi.png" alt>
      </div>
    </div>
    <div v-show="props" class="prop_box">
      <div class="prop_box_top">
        <van-sticky>
          <h1>选择感兴趣的专业</h1>
        </van-sticky>
      </div>
      <div v-for="(v,i) in list" :key="i" class="prop_box_bottom">
        <h2>{{ v.category_name }}</h2>
        <ul v-show="props">
          <li v-for="(item,index) in v.children" :key="index" :class="old_fid==item.id ? 'itemProfress sel' : 'itemProfress'" @click="none(item.id,item.category_name)">{{ item.category_name }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import { getCategoryList, getAppCommonStatis } from '@/api/infohome.js'
import { getagencyInfo, getAppCommon } from '@/api/hometop.js'
import WechatState from '@/utils/isWechatState.js'

export default {
  data() {
    return {
      props: false,
      show: true,
      list: [],
      name: '',
      thumb: '', // 企业头像
      souUrl: '',
      uid: '',
      old_fid: '',
      a: 1,
      b: true
    }
  },
  mounted() {
    // if (!Cookie.get('fid')) {
    //   this.props = true
    // }
    this.old_fid = Cookie.get('fid') || ''
    console.log('old_fid', this.old_fid)

    this.uid = Cookie.get('uid')
    this.categorywFun()
    this.getagencyInfoFun()
    this.getAppCommonStatis()
    this.down_()
  },
  methods: {
    down_() {
      var a = document.getElementsByClassName('jt')[0]
      setInterval(() => {
        if (this.b) {
          this.a = this.a - 0.1
          a.style = `opacity: ${this.a}`
          if (this.a < 0.5) {
            this.b = false
          }
        } else {
          this.a = this.a + 0.1
          a.style = `opacity: ${this.a}`
          if (this.a > 0.9) {
            this.b = true
          }
        }
      }, 150)
    },
    prop() {
      if (this.props && Cookie.get('fid')) {
        this.props = false
        this.show = true
      } else {
        this.props = true
        this.show = false
      }
      this.$emit('showList', this.props)
    },
    none(fid, name) {
      this.show = true
      this.props = false
      this.name = name
      Cookie.set('fid', fid)
      Cookie.set('Fname', name)
      this.$emit('info', fid, name)
      this.$emit('childValue', fid)
      this.$emit('showList', this.props)
      this.old_fid = fid
    },
    // 分类接口
    categorywFun() {
      const date = {
        agency_id: Cookie.get('agency_id') || '2'
      }
      getCategoryList(date).then(res => {
        // console.log(res.result[0])
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result ? res.result : []
          this.list = result
          this.checkIsCacheFid()
        } else {
          this.checkIsCacheFid()
        }
      }).catch(() => {
        this.checkIsCacheFid()
      })
      this.name = Cookie.get('Fname')
    },
    checkIsCacheFid() {
      const fid = Cookie.get('fid')
      const fname = Cookie.get('Fname')
      if (!fid || fid == 'undefined' || !fname) {
        if (this.list.length > 0) {
          const first = this.list[0].children[0]
          this.none(first.id, first.category_name)
        } else {
          this.none('', '')
        }
      }
    },
    getAppCommonStatis() {
      getAppCommonStatis().then(res => {
        this.statis = res.result
        // var bd_script = document.createElement('script')
        // bd_script.type = 'text/javascript'
        // bd_script.innerHTML = this.statis.baidu_h5
        // console.log(this.statis, 'this.statis')
        // document.head.appendChild(bd_script)
        var cc_script = document.createElement('script')
        cc_script.type = 'text/javascript'
        cc_script.innerHTML = this.statis.circle
        document.head.appendChild(cc_script)
        var cz_script = document.createElement('script')
        cz_script.type = 'text/javascript'
        cz_script.src = 'https://s4.cnzz.com/z_stat.php?id=5078536&web_id=5078536'
        // cz_script.innerHTML = this.statis.cnzz
        document.head.appendChild(cz_script)
      })
    },
    // 跳转搜索页面
    goSearch() {
      getAppCommon().then(res => {
        const result = res.result ? res.result : {}
        const sou = result.sou ? result.sou : []
        const sou_url = sou[0] ? sou[0].url : ''
        if (!sou_url) {
          sou_url = 'https://m.www.zhulong.com/search/'
        }
        window.location.href = sou_url
      })
    },
    // 跳转消息页面
    toNews() {
      // const mplogin = WechatState.checkWechatMPLogin()
      // if (!mplogin) return

      getAppCommon().then(res => {
        // const myUrl = res.result.bottom[2].url
        window.location.href = res.result.message.url
      })
    },
    // 获取头部企业信息头像
    getagencyInfoFun() {
      const date = {
        agency_id: 2
      }
      getagencyInfo(date).then(res => {
        this.thumb = res.result.icon_url1
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.s {
  position: fixed;
  top: 0;
  z-index: 13;
  width: 100%;
  background: #fff;
}
.sb {
  position: fixed;
  top: 0;
  z-index: 13000;
  width: 100%;
}
.top {
  width: 90%;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.top_left {
  display: flex;
  background: #ee2e2e;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 56px;
  border-radius: 10px;
  flex-shrink: 0;
  .flimg {
    width: 36px;
    height: 36px;
    margin-right: 8px;
  }
  .class_name {
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 400;
    margin-right: 2px;
  }
  .jt {
    width: 28px;
    margin-top: 4px;
  }
}
.top_middle {
  width: 360px;
  background: #f2f2f2;
  border-radius: 5px;
  height: 56px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 25px;
    height: 25px;
    margin-right: 18px;
  }
  > span {
    font-family: PingFangSC-Regular;
    font-size: 28px;
    color: #999999;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.top_right {
  border-radius: 10px;
  img {
    width: 41px;
    height: 41px;
    vertical-align: middle;
  }
}
.prop_box {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  background: #f2f2f2;
  .prop_box_top {
    width: 100%;
    height: 76px;
    background: white;
    h1 {
      font-size: 0.4rem;
      text-align: center;
      line-height: 76px;
    }
  }
  .prop_box_bottom {
    margin-top: 20px;
    margin-left: 35px;
    margin-bottom: 30px;
    h2 {
      font-size: 0.36rem;
      margin-bottom: 25px;
      color: #454545;
    }
    .itemProfress {
      display: inline-block;
      padding: 22px 20px;
      font-size: 27px;
      border: 2px solid #e5e5e5;
      border-radius: 60px;
      min-width: 150px;
      text-align: center;
      background-color: #fff;
      color: #666;
      margin: 0 10px 10px 0;
      &:hover {
        background: #1f1f22;
        color: #fff;
      }
    }
    .sel {
      background: #1f1f22;
      color: #fff;
    }
  }
}
/deep/.van-sticky {
  background: white;
}
</style>
