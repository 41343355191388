import shouye_request from '@/utils/shouye_request.js'

// 最新弹窗
export function getInventory(params) {
  return shouye_request({
    url: '/home/inventory/getInventory',
    method: 'get',
    params
  })
}

