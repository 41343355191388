<template>
  <div class="main">
    <popup></popup>
    <div v-if="isApp">
      <homebtn v-if="downloadShow" ref="homeFn" @showList="showCategories" @childValue="childValue"></homebtn>
    </div>
    <div ref="swipe" class="topSweipe">
      <!-- v-if="canShowLive"  -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in swiper" :key="index">
          <img :src="item.pic_url" alt style="object-fit: cover;" @click="baidu(item.land_url)">
        </van-swipe-item>
      </van-swipe>
    </div>
    <div v-if="nav_list.length > 0" class="nav_list_box">
      <div class="tieding" :class="zblist.length===0 ? '' : 'hasBorder'">
        <div v-if="showRedPacket && redPacketProgram.length > 0" class="qwert" @click="goRedPacket">
          <img class="list_i" src="https://newoss.zhulong.com/forum/202312/05/8/163908oyeuga5yan5koelr.png" alt>
          <p class="p1">红包</p>
        </div>
        <div v-for="(item,index) in nav_list" :key="index" :class=" canShowLive ? 'qwert' : 'qwert1'" @click="go_detailUrl(item.url,item.name)">
          <img class="list_i" :src="item.pic" alt>
          <p class="p1">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div v-if="!canShowLive" class="hui"></div>
    <div v-show="zblist.length>0 && canShowLive" class="zhibo">
      <p class="my_curriculum_border"></p>
      <b style="font-size: 16px">直播专区</b>
      <div class="zhiboleft" @click="zhibo">
        <span style="font-size: 14px">全部直播&nbsp;></span>
      </div>
    </div>
    <div v-show="zblist.length>0 && canShowLive" class="zongjie">
      <div class="xiao_zbs">
        <ul class="main_title" :class="zblist.length===1?'main_title_width_dengyu_1':'main_title_width'">
          <li v-for="(val, key) in zblist" :key="key" class="morelive_item" :class="zblist.length===1 ? 'morelive_item_width_dengyu_1': zblist.length-1===key ? 'morelive_item_width_last':'morelive_item_width'">
            <a href="javascript:void(0);" class="live_item" :class="zblist.length===1 ? 'morelive_item_width_dengyu_1':'morelive_item_width'" @click="go_live(val)">
              <div class="live_left">
                <img :src="val.teacher_avatar">
                <span class="teacher_name">{{ val.teacher_name }}</span>
              </div>
              <div class="live_right">
                <div class="div_content">
                  <p class="more-title">{{ val.live_name }}</p>
                  <p class="sub_title">{{ val.lesson_name }}</p>
                </div>
                <p v-if="parseInt(val.playing)===1" class="p">
                  <img src="@/assets/play.gif">
                  <span class="sp_iv" style="color: #ee2e2e">正在直播</span>
                  <span class="enter_live">进入直播</span>
                </p>
                <p v-else class="more-yugao">
                  <img src="../../icon/zbyg.png" class="imgsnz">
                  <span class="sp_iv">直播时间：&nbsp;&nbsp;{{ val.start_time_m }}</span>
                </p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <van-overlay v-show="qrCodeModal" @click="qrCodeModal = false">
      <div class="wrapper" @click.stop>
        <div class="div_content">
          <img class="img1" :src="h5_img" @click="go_free_get">
          <img class="img2" src="@/assets/close_activity.png" @click="qrCodeModal = false">

        </div>
      </div>
    </van-overlay>

    <!-- 问答 -->
    <!-- <div v-if="showAiWd">
      <div class="aiTitle">
        <img class="aiIcon" src="https://newoss.zhulong.com/forum/202404/26/12/1714106194823043.png" alt="">
        <b style="font-size: 16px">AI建筑问答</b>
      </div>
      <div class="aiWenda" @click="go_ai_search">
        <img class="aiwd_logo" src="https://newoss.zhulong.com/forum/202407/24/33/181333zecgz3q6cliaxbae.png" alt="">
      </div>
    </div> -->

    <div v-show="banlist.length && canShowLive" class="zhibo1">
      <p class="my_curriculum_border"></p>
      <b style="font-size: 16px">我的班级</b>
    </div>
    <div v-show="banlist.length && canShowLive" class="zongjie11">
      <div v-for="(item, index) in banlist" :key="index" class="xiao" @click="go_url(item.url)">
        <div>
          <img :src="item.thumb" alt class="xiaoimg">
        </div>
        <div class="y">
          <div class="huan">{{ item.lesson_name }}</div>
          <div class="jin">
            <!-- <img src="../../icon/表.png" alt="" /> -->
            <div class="you">
              已学习{{ item.listenCount }}/{{ item.videoCount }}课
              <button class="btn" @click.stop="banji(item)">进入</button>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 10px;height: 100%;  background: rgb(247, 247, 247);flex-shrink: 0;"></div>
    </div>
    <div v-show="ishowLine && banlist.length==0" class="hui"></div>
    <!-- 列表 -->
    <div v-if="!isShowList" class="lists">
      <div v-for="(item,index) in categoryList" :key="index">
        <div class="list_title">
          <div class="left">
            <span class="line_red" />
            <span class="span_name">{{ item.name }}</span>
          </div>
          <span class="span_zhuanye" @click="chooseSub()">全部专业 ></span>
        </div>
        <div v-for="(item,index) in item.list" :key="index">
          <div v-if="item.type === 1" class="threads" @click="clicklist(item.url)">
            <div class="t_top">
              <div class="title">
                <p>{{ item.title }}</p>
              </div>
              <div class="thumb">
                <van-image v-show="item.pic" fit="cover" width="111px" height="67px" :src="item.pic" />
              </div>
            </div>
            <div class="botInfo">
              <div class="botLeft">
                <img class="viewIcon" src="../../assets/eye_img.png">
                <p class="viewNum">{{ item.view_num?item.view_num:0 }}人浏览</p>
                <img class="replyIcon" src="../../assets/yl.png">
                <p class="replyNum">{{ item.reply_num?item.reply_num:0 }}人跟帖</p>
              </div>
              <p class="time">{{ item.date }}</p>
            </div>
          </div>
          <div v-else class="lessons" @click="kclistDetail(item.url)">
            <!-- 课程 -->
            <div class="title">
              <p>{{ item.lesson_name }}</p>
            </div>
            <div class="thumb">
              <!--  width="9.17rem" height="5.16rem" -->
              <van-image width="100%" fit="cover" :src="item.thumb" />
            </div>
            <div class="botInfo">
              <img class="people" src="../../assets/erren.png">
              <p class="peopleNum">{{ item.student_count }}人学习</p>
              <img class="rate" src="../../assets/flw.png">
              <p class="rateNum">{{ item.comment_rate }}好评</p>
            </div>
          </div>
        </div>
      </div>
      <van-list v-show="isShow" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getIndexLists(4)">
        <div v-for="(item,index) in listlist" :key="index">
          <div v-if="item.type === 1" class="threads" @click="clicklist(item.url)">
            <div class="t_top">
              <div class="title">
                <p>{{ item.title }}</p>
              </div>
              <div class="thumb">
                <van-image v-show="item.pic" fit="cover" width="111px" height="67px" :src="item.pic" />
              </div>
            </div>
            <div class="botInfo">
              <div class="botLeft">
                <img class="viewIcon" src="../../assets/eye_img.png">
                <p class="viewNum">{{ item.view_num?item.view_num:0 }}人浏览</p>
                <img class="replyIcon" src="../../assets/yl.png">
                <p class="replyNum">{{ item.reply_num?item.reply_num:0 }}人跟帖</p>
              </div>
              <p class="time">{{ item.date }}</p>
            </div>
          </div>
          <div v-else class="lessons" @click="kclistDetail(item.url)">
            <!-- 课程 -->
            <div class="title">
              <p>{{ item.lesson_name }}</p>
            </div>
            <div class="thumb">
              <!--  width="9.17rem" height="5.16rem" -->
              <van-image width="100%" fit="cover" :src="item.thumb" />
            </div>
            <div class="botInfo">
              <img class="people" src="../../assets/erren.png">
              <p class="peopleNum">{{ item.student_count }}人学习</p>
              <img class="rate" src="../../assets/flw.png">
              <p class="rateNum">{{ item.comment_rate }}好评</p>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div v-show="isApp">
      <dibu v-show="isApp"></dibu>
    </div>
    <!-- 新弹窗 -->
    <newalert :ad-id="19"></newalert>
  </div>
</template>

<script>
import apis from '@/utils/seo'
import homebtn from '@/components/common/homeTop.vue'
import popup from '@/components/common/popup.vue'
import newalert from '@/components/common/newalert.vue'
import dibu from '@/components/common/homeBottom.vue'
import tool from '@/utils/tools.js'
import Cookie from 'js-cookie'
import { getH5IndexDatas, getUserInfo, getWxworkQrcode, getWxUrl, canShowForMp, getPmPlanList } from '@/utils/index.js'
import WechatState from '@/utils/isWechatState.js'

export default {
  components: {
    homebtn,
    dibu,
    popup,
    newalert
  },

  data() {
    var _self = this
    return {
      realIndex: 0,
      list: [1, 2, 3, 4, 5, 6],
      swiperOption: {
        slidesPerView: 1.6,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          slideChangeTransitionEnd: function() {
            if (_self.$refs.mySwiper.swiper) {
              console.log('swiper实例真实下标', _self.$refs.mySwiper.swiper.realIndex)
              _self.realIndex = _self.$refs.mySwiper.swiper.realIndex
            }
          }
        }
      },
      isApp: false,
      ishowLine: false,
      swiper: [],
      zblist: [],
      limit: 50,
      banlist: [],
      listlist: [],
      categoryList: [],
      // url:''
      class_id: '',
      lesson_id: '',
      nav_list: [],
      fid: Cookie.get('fid') || '',
      isShow: false,
      loading: false,
      finished: false,
      downloadShow: true,
      Before_scollH: 0,
      showCapsule: false,
      qrCode: '',
      h5_img: '',
      category_id: '', // 用户兴趣点id
      wx: WechatState.isWechatMiniprogram,
      // false 不展示，该值通过接口获取
      canShowLive: false,

      specialty: '', // 兴趣点
      qrCodeModal: false, // 二维码弹窗
      // 是否在选择专业
      isShowList: false,
      // 是否展示红包任务入口
      showRedPacket: false,
      redPacketProgram: '',
      // 展示问答
      showAiWd: false
    }
  },
  mounted() {
    apis.getSeoMsg(1)
    this.changeHeader()
    // 挂载函数，方便APP调用
    if (tool.isInApp()) {
      this.showAiWd = false
      this.isApp = false
      window.appCacheFid = this.appCacheFid
    } else {
      this.isApp = true
      this.showAiWd = true
    }
    // 头部下拉隐藏,上拉显示
    this.$nextTick(() => {
      const _this = this
      window.onscroll = function() {
        if (_this.isShowList) {
          return
        }
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop <= 0) { // 处理回弹
          _this.downloadShow = true
        } else {
          const differH = scrollTop - _this.Before_scollH
          if (differH == 0) {
            _this.downloadShow = true
          }
          const scollType = differH > 0 ? 'down' : 'up'
          _this.Before_scollH = scrollTop
          if (scollType == 'down') {
            _this.downloadShow = false
          } else if (scollType == 'up') {
            _this.downloadShow = true
          }
        }
      }
    })
    if (Cookie.get('uid')) {
      console.log('已登录')
      this.getUserMessage()
    }
  },
  created() {
    this.$jsbridge.registerHandler('appCacheFid', (data) => {
      const json = JSON.parse(data)
      window.appCacheFid(json.fid, json.fname)
    })
    console.log('fid is get ' + this.fid)
    this.getLiveConfig()
  },
  methods: {
    // 立即领取跳转小程序
    go_free_get() {
      var qrcodeurl = this.qrCode || ''
      /* var title = `全网最火${this.specialty}必备资料集`*/
      /*  var detail = '扫码领取'*/
      var title = `扫码加微`
      var detail = '立即领取1TB建筑资料'
      var qrCodeBg = 'https://newoss.zhulong.com/forum/202305/05/9/105809m1aqm3auxe83vlnf.png'
      var qrCodeTitle = '添加老师'
      const appid = 'gh_7df350421526'
      // 0：体验版 1：正式版
      const wxtype = 1
      var params_str = `qrcodeurl=${qrcodeurl}&title=${title}&detail=${detail}&qrCodeBg=${qrCodeBg}&qrCodeTitle=${qrCodeTitle}`
      var path = `/pages/qrCode/qrCode?${params_str}`
      const uid = Cookie.get('uid') || ''
      if (tool.isInApp()) {
        path = encodeURIComponent(path)
        var url_str = `?url=${path}&wxtype=${wxtype}&appid=${appid}`
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_unitOpenMinProgress.postMessage(url_str)
        } else {
          this.$jsbridge.callHandler(
            'vue_unitOpenMinProgress',
            url_str,
            function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        if (this.wx) {
          this.$wx.miniProgram.navigateTo({
            url: path
          })
        } else {
          if (this.miniProgress) {
            window.open(this.miniProgress)
          }
        }
      }
      __ZL__._tracker('clickAddFriendPopup', { 'qrcode_url': qrcodeurl })
    },
    // 获取打开红包小程序的链接
    getOpenRedpacketMlink() {
      getWxUrl({
        'path': '/pages/RedPacket/index',
        'query': 'red_id=8',
        'env_version': 'release',
        'auth_app_id': 'wx828b87bfa158b387',
        'type': 1
      }).then(res => {
        if (res.result) {
          this.redPacketProgram = res.result
        }
      })
    },
    // 获取用户信息
    getUserMessage() {
      getUserInfo().then((res) => {
        if (res.errNo == 0) {
          this.showCapsule = !res.result.is_wxwork_firend
          this.category_id = res.result.category_id
          this.specialty = res.result.specialty
        }
      }).then(() => {
        // 获取加好友二维码
        if (this.category_id && this.showCapsule) {
          getPmPlanList({ media_id: 320 }).then((res) => {
            if (res.errNo == 0) {
              this.h5_img = res.result[0].pic_url
              this.qrCode = res.result[0].qrcode_url
              getWxUrl({
                'path': '/pages/qrCode/qrCode',
                // 'query': 'jsonstr=' + encodeURIComponent('qrCodeTitle=' + '添加老师' + '&qrcodeurl=' + res.result.qrcode_url + '&title=' + `全网最火${this.specialty}必备资料集` + '&detail=' + '扫码领取' + '&qrCodeBg=' + 'https://newoss.zhulong.com/forum/202305/05/9/105809m1aqm3auxe83vlnf.png' + '&from=' + 'h5_new_people'),
                'query': 'jsonstr=' + encodeURIComponent('qrCodeTitle=' + '添加老师' + '&qrcodeurl=' + this.qrCode + '&title=' + `扫码加微` + '&detail=' + '立即领取1TB建筑资料' + '&qrCodeBg=' + 'https://newoss.zhulong.com/forum/202305/05/9/105809m1aqm3auxe83vlnf.png' + '&from=' + 'h5_new_people'),
                // 'query': 'qrcodeurl=' + res.result.qrcode_url,getWxworkQrcode
                'env_version': 'release',
                'auth_app_id': 'wx828b87bfa158b387',
                'type': 1
              }).then(res => {
                if (res.result) {
                  this.miniProgress = res.result
                }
              })

              /* if (!this.showCapsule) {
                console.log(this.qrCode, 'this.qrCode')
                __ZL__._tracker('addFriendPopup', { 'qrcode_url': this.qrCode })
              }*/
            }
          }).then(() => {
            const now = new Date().getTime()
            // 当天0点的时间戳
            const newDate = tool.get_date('yyyy-mm-dd') + ' 00:00:00'
            const newDateStr = new Date(newDate).getTime()
            // 如果首次进入页面
            if (!localStorage.getItem('qrcode_modal_timestamp_cookies_' + Cookie.get('uid'))) {
              this.qrCodeModal = true
              localStorage.setItem('qrcode_modal_timestamp_cookies_' + Cookie.get('uid'), now)
              console.log('-------------' + this.qrCodeModal)
              __ZL__._tracker('addFriendPopup', { 'qrcode_url': this.qrCode })
            } else {
              // 再次进入页面
              const last = localStorage.getItem('qrcode_modal_timestamp_cookies_' + Cookie.get('uid'))// 最后一次浏览时间
              // 如果当天0点时间<最后一次次浏览时间，不再出弹窗并且存入最新浏览时间戳
              if (newDateStr < last) {
                localStorage.setItem('qrcode_modal_timestamp_cookies_' + Cookie.get('uid'), last)
              } else { // 否则弹窗显示，存入浏览时间的时间戳
                this.qrCodeModal = true
                localStorage.setItem('qrcode_modal_timestamp_cookies_' + Cookie.get('uid'), now)
                __ZL__._tracker('addFriendPopup', { 'qrcode_url': this.qrCode })
              }
            }
            console.log('-------------' + this.qrCodeModal)
          })
        }
      })
    },
    // 调整页面的
    changeHeader() {
      if (tool.isInApp()) {
        this.isApp = false
        this.$refs.swipe.style.marginTop = '0px'
      } else {
        this.isApp = true
      }
    },
    // 选择专业
    childValue(val) {
      this.fid = val
      this.before_getIndexLists()
    },
    // 展示分类
    showCategories(val) {
      this.isShowList = val
      // chrome
      document.body.scrollTop = 0
      // firefox
      document.documentElement.scrollTop = 0
    },
    kclistDetail(url) {
      if (tool.isInApp()) {
        this.openAppLesson(url)
      } else {
        window.location.href = url
      }
    },
    go_detailUrl(url, name) {
      // const mplogin = WechatState.checkWechatMPLogin()
      // if (!mplogin) return

      // 金刚取按钮 121
      if (tool.isInApp()) {
        if (name === '训练营') {
          this.openAppTab(1)
        } else if (name === '图片库') {
          var path = window.location.href + 'tupianku'
          if (process.env.NODE_ENV === 'production') {
            path = window.location.href + 'shouye/' + 'tupianku'
          }
          this.unitAppOpenUrl(path)
        } else {
          this.unitAppOpenUrl(url)
        }
      } else {
        if (name === '图片库') {
          this.$router.push('/tupianku')
        } else {
          window.location.href = url
        }
      }
    },
    // 任务红包
    goRedPacket() {
      var path = '/pages/RedPacket/index?red_id=8'
      this.$wx.miniProgram.navigateTo({
        url: path
      })
    },

    go_url(url) {
      if (tool.isInApp()) {
        this.unitAppOpenUrl(url)
      } else {
        window.location.href = url
      }
    },
    before_getIndexLists() {
      this.page = 1
      this.listlist = []
      this.categoryList = []
      // type 1：轮播图 2：直播 3：我的班级 4：列表
      this.getIndexLists(1)
      this.getIndexLists(4)
      this.getIndexLists(2)
      this.getIndexLists(3)
    },
    // 获取是否显示直播
    getLiveConfig() {
      // 其他 请求接口
      canShowForMp().then(res => {
        console.log(res)
        const show = res.result || 0
        this.canShowLive = (show == 1)
      }).then(res => {
        // 在此请求数据
        this.before_getIndexLists()
        // 红包菜单入口在此开启和关闭
        // if (WechatState.isWechatMiniprogram) {
        //   this.getOpenRedpacketMlink()
        //   this.showRedPacket = true
        // }
      })
      // // iOS 不用请求 其他的请求接口
      // if (tool.isIosWxMiniprogram()) {
      //   // iOS小程序 设为false，不展示
      //   this.canShowLive = false
      //   // 在此请求数据
      //   this.before_getIndexLists()
      // } else {
      //   // 其他 请求接口
      //   canShowForMp().then(res => {
      //     console.log(res)
      //     const show = res.result || 0
      //     this.canShowLive = (show == 1)
      //   }).then(res => {
      //     // 在此请求数据
      //     this.before_getIndexLists()
      //   })
      // }
    },
    // 有fid 的请求
    getIndexLists(type) {
      // type 1：轮播图 2：直播 3：我的班级 4：列表
      var params = {
        getType: type,
        page: this.page,
        limit: this.limit
      }
      if (this.fid && this.fid !== '') {
        params.category_id = this.fid
      }
      getH5IndexDatas(params).then((res) => {
        this.loading = false
        this.ishowLine = true
        const result = res.result || {}
        const nav = result.nav || []
        if (this.canShowLive) {
          this.nav_list = nav.filter(e => (e.name != 'AI问答'))
        } else {
          this.nav_list = nav.filter(e => (e.name != '训练营' && e.name != '优惠券' && e.name != '图片库' && e.name != '论坛' && e.name != 'AI问答'))
        }
        // type 1：轮播图 2：直播 3：我的班级 4：列表
        if (type === 1) {
          this.swiper = res.result.banner
        } else if (type === 2) {
          const lives = res.result.liveList
          this.zblist = lives
        } else if (type === 3) {
          this.banlist = res.result.class
        } else if (type === 4) {
          var list = res.result.list ? res.result.list : []
          if (!this.canShowLive) {
            list = list.filter(e => e.type == 2 && e.lesson_id)
          }
          if (this.page === 1 && this.canShowLive) {
            var obj = {
              title: '56岁总工二十年收藏—336个技术交底模板（word版可编辑）',
              pic: 'https://newoss.zhulong.com/forum/202203/21/17/1647856643489916.jpeg',
              view_num: '666',
              reply_num: '1',
              date: '2024-05-20',
              tid: '50335665',
              type: 1,
              url: 'https://m.zhulong.com/bbs/d/50335665.html'
            }
            list.splice(2, 0, obj)
            this.listlist = list
          } else {
            this.listlist = this.listlist.concat(list)
          }
          if (this.listlist.length > 0) {
            this.isShow = true
          } else {
            this.isShow = false
          }
          const maxpage = res.result.maxpage ? res.result.maxpage : 1
          if (this.page < maxpage) {
            this.page++
          } else {
            this.finished = true
          }
        }
      })
    },
    chooseSub() {
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_choose_subject.postMessage('1')
        } else {
          this.$jsbridge.callHandler(
            'vue_choose_subject'
            , 'vue_choose_subject'
            , function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        this.$refs.homeFn.prop()
      }
    },
    zhibo() {
      // const mplogin = WechatState.checkWechatMPLogin()
      // if (!mplogin) return

      if (tool.isInApp()) {
        var path = window.location.href + 'zhibo'
        if (process.env.NODE_ENV === 'production') {
          path = window.location.href + 'shouye/' + 'zhibo'
        }
        this.unitAppOpenUrl(path)
      } else {
        this.$router.push('/zhibo')
      }
    },
    banji(item) {
      const class_id = item.class_id
      const lesson_id = item.lesson_id
      const student_id = item.id
      const class_name = item.class_name
      if (tool.isInApp()) {
        var path = window.location.origin + '/edu/banji' + '?class_id=' + class_id + '&lesson_id=' + lesson_id +
          '&student_id=' + student_id + '&class_name=' + class_name
        if (tool.isInIosApp()) {
          // 汉字转码
          const c_name = encodeURIComponent(class_name)
          // ios 处理
          if (process.env.NODE_ENV === 'production') {
            path = window.location.origin + '/edu/' + 'banji' + '?class_id=' + class_id + '&lesson_id=' + lesson_id +
              '&student_id=' + student_id + '&class_name=' + c_name
          }
        }
        this.unitAppOpenUrl(path)
      } else {
        location.href = window.location.origin + '/edu/banji?class_id=' + class_id + '&lesson_id=' + lesson_id + '&student_id=' + student_id + '&class_name=' + class_name
        // this.$router.push({
        //   path: '/banji',
        //   query: {
        //     class_id: class_id,
        //     lesson_id: lesson_id,
        //     student_id: student_id,
        //     class_name: class_name
        //   }
        // })
      }
    },
    baidu(urls) {
      // const mplogin = WechatState.checkWechatMPLogin()
      // if (!mplogin) return

      if (tool.isInApp()) {
        this.unitAppOpenUrl(urls)
      } else {
        window.location.href = urls
      }
    },
    clicklist(urls) {
      // const mplogin = WechatState.checkWechatMPLogin()
      // if (!mplogin) return

      if (tool.isInApp()) {
        this.unitAppOpenUrl(urls)
      } else {
        window.location.href = urls
      }
    },
    // app 统一打开链接
    unitAppOpenUrl(url) {
      if (tool.isInIosApp()) {
        window.webkit.messageHandlers.vue_openUrl.postMessage(url)
      } else {
        this.$jsbridge.callHandler(
          'vue_openUrl'
          , url
          , function(responseData) {
            // android 返参数接受
          }
        )
      }
    },
    // 打开tabbar
    openAppTab(index) {
      if (tool.isInIosApp()) {
        window.webkit.messageHandlers.vue_openTabbar.postMessage(index)
      } else {
        this.$jsbridge.callHandler(
          'vue_openTabbar'
          , index
          , function(responseData) {
            // android 返参数接受
          }
        )
      }
    },
    // 打开APP课程
    openAppLesson(url) {
      if (tool.isInIosApp()) {
        window.webkit.messageHandlers.vue_openLesson.postMessage(url)
      } else {
        this.$jsbridge.callHandler(
          'vue_openLesson'
          , url
          , function(responseData) {
            // android 返参数接受
          }
        )
      }
    },
    // APP调用 存储id
    appCacheFid(fid, name) {
      const ffid = Number(fid)
      if (ffid > 0) {
        Cookie.set('fid', ffid)
        this.fid = fid
        this.before_getIndexLists()
      }
      if (name && name.length > 0) {
        Cookie.set('Fname', name)
      }
    },
    go_live(item) {
      // const mplogin = WechatState.checkWechatMPLogin()
      // if (!mplogin) return

      if (tool.isInApp()) {
        if (item.playing === 1) {
          // 去直播
          const livePath = 'lesson_id=' + item.lesson_id + '&live_id=' + item.id + '&wk_id=' + item.wk_id
          if (tool.isInIosApp()) {
            window.webkit.messageHandlers.vue_openLive.postMessage(livePath)
          } else {
            this.$jsbridge.callHandler(
              'vue_openLive'
              , livePath
              , function(responseData) {
                // android 返参数接受
              }
            )
          }
        } else {
          // 课程详情
          if (tool.isInIosApp()) {
            window.webkit.messageHandlers.vue_openLesson.postMessage(item.view_url)
          } else {
            this.$jsbridge.callHandler(
              'vue_openLesson'
              , item.view_url
              , function(responseData) {
                // android 返参数接受
              }
            )
          }
        }
      } else {
        window.location.href = item.view_url
      }
    },
    go_ai_search() {
      var urls = 'https://m.zhulong.com/ucenter/standardSeach'
      if (tool.isInApp()) {
        this.unitAppOpenUrl(urls)
      } else {
        window.location.href = urls
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  padding: 0 30px 0;
  background: #f7f7f7;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .div_content{
    margin:  0 auto;
    width: 600px;
    .img1{
      width: 600px
    }
    .img2{
      display:block;
      width: 60px;
      margin: 24px auto 0;

    }
  }
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.xiao {
  height: 80%;
  display: flex;
  border-radius: 12px;
  background: white;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  margin: 0px 16px 0 0;
  padding: 0px 8px;
  width: 91%;
}
  /* 轮播图 */
  .swiper-container {
    width: 100%;
    height: 220px;
    background: rgb(247, 247, 247);
    overflow: visible;
    .div_bj{
      display: flex;
      background: rgb(247, 247, 247);
      align-items: center;
      justify-content: start;
      padding-left: 14px;
      border-radius: 5px;
      .div_bj_img{
        height: 110px;
        display: flex;
        background: white;
        justify-content: space-around;
        align-items: center;
        flex-shrink: 0;
        width: 170px;
        border-radius: 10px;
      }
      .div_bj_content{
        transform: scale(0.85);
        .title{
          width: 100%;
          height: 83px;
          overflow: hidden;
          white-space: normal;
          font-size: 20px;
          text-align: start;
          padding-top: 15px;
          transform: scale(0.98);
        }
        .div_bj_content_bottom{
          width: 100%;
          font-size: 20px;
          text-align: start;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: rgb(222, 91, 86);
          span{
            transform: scale(0.94);
          }

          .bottom_btn {
            width: 100px;
            height: 43px;
            line-height: 45px;
            text-align: center;
            border-radius: 10px;
            color: rgb(255, 255, 255);
            background: red;
            transform: scale(0.9);

          }
        }
      }
    }

  }
  .swiper-slide {
    /* width: 50% !important; */
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(1);
    height: 80%;
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1.5);
    z-index: 9;
  }
  /deep/.swiper-slide{
    .div_bj{
      width:100%;
      background-color:#ffffff;
      filter:alpha(opacity=70);
      margin-top: 12px;
      -moz-opacity:0.7;
      opacity:0.7;
      border: 0.5px solid #f2f2f2;
      box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.2);
    }

  }
  /deep/.swiper-slide-active{
    .div_bj{
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.1);
      border: 0px solid #f2f2f2;
      filter:alpha(opacity=100);
      -moz-opacity:1;
      opacity:1;
      background: white;
    }
  }

  .swiper-slide img {
    width: 100%;
  }

  .swiper-button-prev{
    left: 30px;
    right: auto;
  }

  .swiper-button-prev:after{
    content:'none'
  }

  .swiper-button-next{
    right: 30px;
    left: auto;
  }

  .swiper-button-next:after{
    content:'none'
  }
  /* 轮播图 */

.zuo11 {
}
.kclistImg {
  width: 688px;
  height: 387px;
  margin: 0 auto;
  margin-top: 25px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }
}
.kclistTitle {
  font-size: 34px;
  font-weight: 600;
  padding: 37px 0 0 40px;
}
.kclist {
  width: 100%;
  height: 567px;
  border-bottom: 2px solid #e8e8e8;
}
.tzlist {
  margin-bottom: 120px;
}
.zbtime {
  position: absolute;
  right: -15px;
  bottom: 40px;
}
.xiao_img {
  width: 28px;
  height: 19px;
  margin-left: 5px;
}
.xiao_img11 {
  width: 30px;
  height: 22px;
  margin-left: 5px;
}

.xx_bottom {
  width: 360px;
  border-radius: 20px;
  height: 100%;
  background: rgb(247, 247, 247);
  margin-right: 20px;
}
.xx_bottom_dengyu_1 {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  background: rgb(247, 247, 247);
  margin-right: 20px;
}
.div_live_public {
  .c_name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: pre-wrap;
    margin-left: 20px;
    color: #353535;
  }
  .div_time {
    margin-left: 110px;
    .timespan {
      color: #9c9c9c;
    }
    .imgsnz {
      width: 29px;
      height: 29px;
      float: left;
      margin-right: 12px;
    }
  }
  .div_live {
    display: flex;
    align-items: center;
    height: 72px;
    margin: 30px 20px 20px 20px;
    img {
      width: 72px;
      height: 72px;
      border-radius: 50%;
    }
  }
}

.asdasd {
  width: 280px;
  height: 100px;
  margin-top: 20px;
}
.xx_top {
  width: 106px;
  height: 100%;
  float: left;
}
.xx_top img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: 31px 0 0 20px;
}
.xiaoimg {
  width: 252px;
  height: 154px;
  border-radius: 10px;
}
.naozhong {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-left: 120px;
}
.bao {
  margin-left: 10px;
  display: inline-block;
  span {
    color: #999999;
  }
}
.bao11 {
  margin-left: 10px;
  display: inline-block;
  span {
    color: #999999;
  }
}
.xiao9 {
}
.xiao0 {
  margin-left: 30px;
  height: 100%;
  img {
    width: 25px;
    height: 25px;
  }
}
.xiao00 {
  width: 50%;
  img {
    width: 25px;
    height: 27px;
    position: relative;
    bottom: 2px;
  }
}
.zuo11 {
  width: 60%;
  display: flex;
  position: relative;
  top: 25px;
  left: 20px;
  background-color: greenyellow;
}
.you1 {
  width: 40%;
  height: 100%;
  // background: cornflowerblue;
}
// .top {
//   width: 94%;
//   height: 50px;
//   margin: 0 auto;
//   // background: chartreuse;
//   border-bottom: 2px solid #e8e8e8;
//   position: relative;
//   display: flex;
// }
.wan {
  width: 200px;
  display: block;
}
.kaobei {
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-left: 20px;
  img {
    width: 35px;
    height: 35px;
  }
  span {
    margin-top: 3px;
    margin-left: 20px;
    // margin-left: -60px;
  }
}
.riqi {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 20%;
  margin-left: 20px;
  // background: chocolate;
}

.img {
  width: 100%;
  height: 220px;
  margin-top: 10px;
  // background: chartreuse;
  img {
    width: 100%;
    height: 76%;
    border-radius: 14px;
  }
}
.right {
  width: 40%;
  height: 100%;
  p {
    position: relative;
    bottom: 10px;
    left: 150px;
    color: #999999;
    width: 150px;
  }
  // background: darkcyan;
}
.hh1 {
  width: 300px;
  font-size: 30px;
}
.left2 {
  width: 100%;
  height: 100px;
  // background: cornflowerblue;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.hh {
  width: 380px;
  height: 110px;
  margin-left: 10px;
  margin-top: 38px;
  margin-left: 10px;
  font-size: 34px;
  letter-spacing: 2px;
}
.jing {
  width: 40px;
  height: 40px;
  background: red;
  text-align: center;
  line-height: 40px;
  margin-top: 13px;
  margin-left: 20px;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  position: absolute;
}
.left1 {
  display: flex;
  width: 100%;
  height: 180px;
  overflow: hidden;
  // line-height: 150px;
  // background: chartreuse;
}
.left {
  width: 60%;
  height: 100%;
  // background: darkblue;
}
.retie {
  width: 94%;
  height: 216px;
  // background: red;
  display: flex;
  margin: 0 auto;
  position: relative;
  // border-bottom: 2px solid rgb(190, 186, 186);
}
.btn {
  width: 100px;
  height: 43px;
  margin-left: 60px;
  line-height: 45px;
  text-align: center;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  background: red;
}
.you {
  margin-top: 30px;
  font-size: 28px;
  color: rgb(222, 91, 86);
}
.jin1 {
  width: 340px;
  height: 60px;
  display: flex;
  // background: royalblue;
  img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
  }
}
.huan {
  width: 340px;
  height: 83px;
  overflow: hidden;
  // background: royalblue;
  // flex-wrap: wrap;
  // overflow: hidden;
  white-space: normal;
  font-size: 28px;
  padding-top: 15px;
}
.y {
  width: 360px;
  height: 76%;
  // background: rosybrown;
}
.youzi {
  width: 460px;
  margin-left: 250px;
  height: 100%;
  // background: red;
  position: relative;
  h3 {
    position: absolute;
    top: 30px;
  }
  p {
    position: absolute;
    top: 80px;
    left: 10px;
    font-size: 30px;
    color: rgb(176, 176, 176);
  }
  h4 {
    color: red;
    font-size: 35px;
    position: absolute;
    top: 140px;
    left: 10px;
  }
}
.zuotu {
  width: 240px;
  height: 150px;
  background: saddlebrown;
  border-radius: 16px;
  position: absolute;
  top: 13%;
  left: 0;
}
.zuixin {
  width: 92%;
  height: 210px;
  border-bottom: 3px solid #eee;
  margin: 0 auto;
  // background: royalblue;
  position: relative;
}
.hui {
  width: 100%;
  height: 25px;
  background: #f7f7f7;
}
.shijian {
  margin-top: 20px;
  margin-left: 5px;
}
.xia {
  width: 100%;
  height: 85px;
  // background: chocolate;
  text-align: center;
  display: flex;
  img {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-left: 120px;
  }
}
.shang {
  width: 100%;
  height: 100px;
  // background: cornflowerblue;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ming {
  width: 100px;
  height: 50px;
  // background: seashell;
  text-align: center;
  line-height: 50px;
  margin-left: 0px;
  margin-top: 10px;
}
.tou {
  width: 76px;
  height: 76px;
  margin-top: 10px;
  background: rgb(187, 194, 190);
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
  }
}

.xiao_zbs {
  padding-left: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-top: 10px;
}
.xiao1:last-child {
  margin-right: 20px !important;
}
.xiao1 {
  width: 330px;
  height: 185px;
  background: rgb(247, 247, 247);
  display: inline-block;
  flex-shrink: 0;
  border-radius: 15px;
}
.xiao121 {
  width: 345px;
  height: 185px;
  display: inline-block;
  flex-shrink: 0;
  border-radius: 15px;
}

.xx {
  border-radius: 20px;
  height: 185px;
  margin: 0 auto;
  display: flex;
}
.xx_dengyu_1 {
  border-radius: 20px;
  height: 185px;
  width: 100%;
  padding-right: 20px;
  margin: 0 auto;
  display: flex;
}
.header {
  width: 100%;
  height: 174px;
  background: rgb(42, 42, 44);
  display: flex;
  justify-content: space-around;
}
.topSweipe {
  margin-top: 88px;
  margin-bottom: 20px;
}
.my-swipe{
  border-radius: 12px;
  /deep/.van-swipe__indicator{
    width: 30px;
    height: 6px;
    border-radius: 12px;
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  // line-height: 376px;
  text-align: center;
  background-color: #fff;
  height: 380px;
  // position: relative;
  // width: 100%;
  // height: 0;
  // padding-top: 100%;
  img {
    width: 100%;
    height: 376px;
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 56.3%;
  }
}
.nav_list_box{
  background: #fff;
  border-radius: 12px;
  overflow-x: scroll;
}
.tieding {
  width: 100%;
  height: 237px;
  margin: 0 auto;
  display: flex;
  // justify-content: space-around;
  .qwert {
    width: 18.2%;
    height: 149px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    position: relative;
  }
  .qwert1 {
    width: 25%;
    height: 149px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    position: relative;
  }
}
.hasBorder {
  // border-bottom: 1.5px solid #eee;
  background: #fff;
  border-radius: 12px;
}
.zhibo {
  width: 99%;
  height: 40px;
  margin: 0 auto;
  // background: #eee;
  margin: 24px 0 16px;
  line-height: 40px;
  position: relative;
  b {
    margin-left: 15px;
  }
}
.zhibo1 {
  width: 99%;
  // height: 60px;
  margin: 0 auto;
  // background: #eee;
  margin-top: 24px;
  // line-height: 40px;
  position: relative;
  b {
    margin-left: 15px;
  }
}
.list_i {
  width: 83px;
  height: 83px;
  background-size: 100% auto;
}
.i1 {
  width: 91px;
  height: 91px;
  background: url(../../icon/head_campclass@2x.png) no-repeat;
  background-size: 100% auto;
}
.i2 {
  width: 91px;
  height: 91px;
  background: url(../../icon/head_photo@2x.png) no-repeat;
  background-size: 100% auto;
}
.i3 {
  width: 91px;
  height: 91px;
  background: url(../../icon/head_group@2x.png) no-repeat;
  background-size: 100% auto;
}
.i4 {
  width: 91px;
  height: 91px;
  background: url(../../icon/矩形10@2x.png) no-repeat;
  background-size: 100% auto;
}
.i5 {
  width: 91px;
  height: 91px;
  background: url(../../icon/圆角矩形6拷贝@2x.png) no-repeat;
  background-size: 100% auto;
}
.p1 {
  position: absolute;
  bottom: 7px;
  font-size: 28px;
  text-align: center;
}
.zhiboleft {
  position: absolute;
  right: 5px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(153, 153, 153);
  span {
    display: inline-block;
    float: right;
    // margin-right: 20px;
  }
}
.zhii {
  width: 40px;
  height: 40px;
  // background: chartreuse;
  font-size: 30px;
  margin-left: 10px;
}
.zongjie {
  width: 100%;
  height: 280px;
  // background: blue;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background: #fff;
  border-radius: 12px;
}
.zongjie11 {
  background: rgb(247, 247, 247);
  align-items: center;
  display: flex;
  // padding-left: 18px;
  overflow-x: auto;
  height: 276px;
  // background: blue;
  overflow-y: hidden;
  white-space: nowrap;
}
.my_curriculum_border {
  float: left;
  display: inline-block;
  width: 5px;
  height: 32px;
  background: #db403b;
  margin: 4px 0px 0px 0;
}
// 实在是区分不开写的样式了，没法改重写样式，上面的样式继续保留，不敢删啊
// 帖子列表新样式
.lists {
  width: 100%;
  height: 100%;
  // background-color: #fff;

  .list_title {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    .left {
      display: flex;
      align-items: center;
      .line_red {
        width: 5px;
        display: inline-block;
        height: 32px;
        background: #ee2e2e;
        margin-left: 32px;
        margin-right: 16px;
      }
      .span_name {
        font-size: 27px;
        color: #333333;
        font-weight: 700;
      }
    }
    .span_zhuanye {
      color: #999999;
      margin-right: 30px;
      font-size: 27px;
    }
  }
}
.threads {
  width: 100%;
  // height: 230px;
  padding: 30px 30px 0 30px;
  margin: 0 0 20px 0;
  width: calc(100% - 60px);
  // border-bottom: 2px solid #e8e8e8;
  background: #fff;
  border-radius: 12px;
  .t_top {
    width: 100%;
    min-height: 65px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    .title {
      flex: 1;
      p {
        color: #333;
        font-size: 30px;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .thumb {
      margin-left: 40px;
    }
  }
  .botInfo {
    margin-top: 25px;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #999;
    font-size: 24px;
    .botLeft {
      width: calc(100% - 200px);
      display: flex;
      align-items: center;
      .viewIcon {
        margin-left: 2px;
        width: 28px;
        height: 19px;
      }
      .viewNum {
        margin-left: 8px;
        display: inline-block;
      }
      .replyIcon {
        margin-left: 40px;
        width: 25px;
        height: 25px;
      }
      .replyNum {
        margin-left: 8px;
        display: inline-block;
      }
    }
    .time {
      margin-right: 20px;
      width: 180px;
      text-align: right;
      color: #999;
      font-size: 24px;
    }
  }
}
.lessons {
  // width: 100%;
  // height: 600px;
  // margin-left: 30px;
  width: calc(100% - 60px);
  // border-bottom: 2px solid #e8e8e8;
  border-radius: 12px;
  background: #fff;
  padding: 20px 30px 0 30px;
  .title {
    margin-top: 10px;
    // margin-left: 30px;
    width: calc(100% - 60px);
    p {
      color: #333;
      font-size: 30px;
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .thumb {
    // width: 688px;
    // height: 387px;
    margin: 0 auto;
    margin-top: 25px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 14px;
    }
  }
  .botInfo {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #999;
    font-size: 24px;
    .people {
      // margin-left: 32px;
      width: 32px;
      height: 26px;
    }
    .peopleNum {
      margin-left: 8px;
      display: inline-block;
    }
    .rate {
      margin-left: 40px;
      width: 29px;
      height: 29px;
    }
    .rateNum {
      margin-left: 8px;
      display: inline-block;
    }
  }
}
/deep/.van-image__img {
  border-radius: 10px;
}
.main_title_width {
  padding: 0 34px 23px 0;
}
.main_title_width_dengyu_1 {
  width: calc(100% - 23px);
  padding-bottom: 34px;
}
.main_title {
  display: flex;
  height: 100%;

  align-items: center;
  flex-shrink: 0;
  .morelive_item_width_dengyu_1 {
    width: 99%;
  }
  .morelive_item_width {
    width: 480px;
    margin-right: 34px;
  }
  .morelive_item_width_last {
    width: 480px;
  }
  ul:last-child {
    margin-left: 34px;
  }

  .morelive_item {
    height: 220px;
    float: left;
    position: relative;
    border-radius: 10px;
    background: #f7f7f7;
    // border: 1px solid #e6e6e6;
    .live_item {
      height: 100%;
      display: flex;
      .live_left {
        padding-top: 5px;
        padding-bottom: 40px;
        margin-top: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        .teacher_name {
          font-size: 24px;
          color: #999999;
        }
        & > img {
          width: 79px;
          height: 79px;
          // display: inline-block;
          overflow: hidden;
          border-radius: 50%;
          margin: 10px 23px 3px 23px;
          border: 0 solid #e6e6e6;
        }
      }
      .live_right {
        height: 184px;
        padding-top: 28px;
        padding-bottom: 10px;
        width: 66%;
        display: flex;
        position: relative;
        flex-direction: column;
        .div_content {
          width: 100%;
        }
        .p {
          display: flex;
          position: absolute;
          bottom: 0px;
          margin-bottom: 20px;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
          }
          .enter_live {
            background-color: #ee2e2e;
            display: inline-block;
            color: #fff;
            padding: 2px 10px;
            border-radius: 20px;
            font-size: 24px;
            margin-left: 8px;
          }
        }
      }
    }

    p {
      & > img {
        width: 16px;
        vertical-align: sub;
        margin-right: 6px;
      }
    }

    .more-title {
      // height: 70px;
      // padding-top: 23px;
      font-size: 28px;
      color: #333333;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      white-space: pre-wrap;

      &:hover {
        color: #ee2e2e;
      }
    }
    .sub_title {
      font-size: 26px;
      color: #999999;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-top: 12px;
      white-space: pre-wrap;

      // margin-bottom:20px;
    }
    .more-yugao {
      position: absolute;
      bottom: 0px;
      color: #adadad;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 24px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
.newPeople{
  margin:10px 20px;
  position:relative;
  background: url('https://newoss.zhulong.com/forum/202305/10/5/1642055xcmgxsxokmdfkec.png') no-repeat;
  background-size: 100% 100%;
  width: 710px;
  height: 144px;
  .freeGet{
    font-size: 32px;
    font-weight: 600;
    padding: 76px 0 0 196px;
    color: #663B16;
    font-family: PingFangSC-Regular;
  }
}
.aiTitle{
  width: 99%;
  height: 40px;
  margin: 0 auto;
  // background: #eee;
  margin: 24px 0 16px;
  line-height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  .aiIcon{
    width: 30px;
    // float: left;
    // display: inline-block;
    // width: 5px;
    // height: 32px;
    // background: #db403b;
    // margin: 4px 0px 0px 0;
  }
  b {
    margin-left: 15px;
  }
}
.aiWenda{
  background: #fff;
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 12px;
  .aiwd_logo {
    width: 100%;
    display: block;
  }
  .aiinput{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 4px solid #ee2e2e;
    border-radius: 6px;
    margin: 0 0 20px;
    background: #fbf0f0;
    .aitext{
      padding: 10px 0 10px 15px;
      font-size: 26px;
    }
    .aibtn{
      background: #ee2e2e;
      color: #fff;
      padding: 10px 27px;
      border-radius: 20px 0 0 0;
      font-size: 28px;
      margin-right: -2px;
    }
  }
  .aiTip{
    color: #666;
    font-size: 26px;
  }
}
/deep/.s{
  .top{
    margin-left: 0;
  }
}
// 隐藏滚动条
::-webkit-scrollbar {
  display: none;
}
</style>
