import request from '@/utils/request_bbs'
import shouye_request from '@/utils/shouye_request'

// 精华贴子接口
export function getFidEssenceT(params) {
  return request({
    url: '/thread/thread/getFidEssenceT',
    method: 'get',
    params
  })
}
// WabFind页面
export function findDate(params) {
  return request({
    url: '/group/group/getGroupListByCategoryID',
    methods: 'get',
    params
  })
}
// WabFind页面分类
export function getCategoryList(params) {
  return shouye_request({
    url: '/index/index/getCategoryList',
    methods: 'get',
    params
  })
}

// 图库帖子
export function gitPhoto(params) {
  return request({
    url: '/thread/thread/getPhotoList',
    methods: 'get',
    params
  })
}
// 三方统计
export function getAppCommonStatis(params) {
  return request({
    url: '/api/Agency/getAppCommonStatis',
    methods: 'get',
    params
  })
}

// 获取分享地址 /thread/thread/getShares
export function getShares(params) {
  return request({
    url: '/thread/thread/getShares',
    method: 'get',
    params
  })
}
