
import request from '@/utils/request_bbs'
// 获取弹窗
export function getImgUrl(params) {
  return request({
    url: '/admin/appSet/popWindowPop',
    methods: 'get',
    params
  })
}

