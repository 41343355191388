<template>
  <div class="navMain content-bottom">
    <div v-for="(v,i) in list" :key="i">
      <div v-show="active!==i" class="navItem" @click="selected(i,v)">
        <img :src="v.thumb" alt="">
        <p>{{ v.name }}</p>
      </div>
      <div v-show="active===i" class="navItem" @click="selected(i,v)">
        <img :src="v.red_thumb" alt="">
        <p class="color">{{ v.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import { getAppCommon } from '@/api/hometop.js'
import WechatState from '@/utils/isWechatState.js'
import { canShowForMp } from '@/utils/index.js'
import tool from '@/utils/tools'

export default {
  props: {
    actived: {
      type: Number,
      default: null
    },
    fid: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      active: '',
      list: [],
      app_id: '',
      agency_id: '',
      fids: this.fid,
      canShowMp: true
    }
  },
  mounted() {
    this.agency_id = Cookie.get('h5_agency_id')
    this.app_id = Cookie.get('h5_app_id')
    this.getAllConfig()
  },
  methods: {
    // 是否能够显示课程、直播
    getAllConfig() {
      // 在此请求数据
      canShowForMp().then(res => {
        console.log(res)
        const show = res.result || 0
        this.canShowMp = (show == 1)
      }).then(res => {
        // 在此请求数据
        this.getAppCommonFun()
      })
      // // iOS 不用请求 其他的请求接口
      // if (tool.isIosWxMiniprogram()) {
      //   // iOS小程序 设为false，不展示
      //   this.canShowMp = false
      //   // 在此请求数据
      //   this.getAppCommonFun()
      // } else {
      //   canShowForMp().then(res => {
      //     console.log(res)
      //     const show = res.result || 0
      //     this.canShowMp = (show == 1)
      //   }).then(res => {
      //   // 在此请求数据
      //     this.getAppCommonFun()
      //   })
      // }
    },
    // 获取菜单
    getAppCommonFun() {
      if (this.agency_id === 1) {
        this.active = ''
      } else {
        if (this.fids) {
          this.active = 3
        } else {
          this.active = 0
        }
      }
      const date = {
        app_id: this.app_id,
        agency_id: this.agency_id
      }
      getAppCommon(date).then(res => {
        console.log(res)
        if (res.errNo == 0) {
          const result = res.result || {}
          const list = result.bottom || []
          if (!this.canShowMp) {
            this.list = list.filter(e => e.name != '资料')
          } else {
            this.list = list
          }
        }
      })
    },
    selected(i, v) {
      var fid = Cookie.get('fid')
      // console.log(v.path + '?' + 'fid' + '=' + fid)
      // console.log(i)
      // if (i == 4) {
      //   // ucenter
      //   const mplogin = WechatState.checkWechatMPLogin()
      //   if (!mplogin) return
      // }
      this.active = i
      if (v.path === '') {
        if (this.agency_id !== 1) {
          if (i === 0) {
            console.log(fid)

            this.$router.push({
              path: v.path,
              query: {
                fid: fid
              }
            })
          } else {
            window.location.href = v.url
          }
        }
      } else {
        this.$router.push({
          path: v.path,
          query: {
            fid: fid
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navMain {
  height: 106px;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  background: #fff;
  left: 0;
  width: 100%;
  border-top: 2px solid #ddd;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
  .navItem {
    img {
      width: 45px;
      height: 45px;
    }
    .active {
      color: #ee2e2e;
    }
  }
}
.color {
  color: red;
}
</style>
