<!--  侧边栏咨询   -->
<template>
  <div v-if="isShow&&imgU" class="container">
    <div class="content">
      <div class="img_box">
        <img :src="imgU" alt="" @click="goActive">
        <!-- <img src="../../assets/popup_img.png" alt="" @click="goActive"> -->
      </div>
      <div class="close">
        <img src="https://z3.ax1x.com/2021/11/05/Iub7Y4.png" alt="" @click="isShow = false">
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import tool from '@/utils/tools.js'
import { getImgUrl } from '@/api/popup'
export default {
  data() {
    return {
      isShow: false,
      imgU: '',
      link: '',
      start: '',
      end: '',
      fid: Cookies.get('fid') ? Cookies.get('fid') : '',
      type: 3 // 1安卓，2IOS，3H5
    }
  },
  computed: {},
  created() {
    this.agency_id = Cookies.get('h5_agency_id')
    this.app_id = Cookies.get('h5_app_id')
    if (tool.isInApp()) {
      this.type = 1
      if (tool.isInIosApp()) {
        this.type = 2
      }
    }
    this.getImg()
  },
  mounted() {
  },
  // 销毁监听
  destroyed() { },
  methods: {
    getImg() {
      getImgUrl({ app_id: this.app_id, agency_id: this.agency_id, category_ids: this.fid, type: this.type }).then((res) => {
        this.imgU = res.result.phone_img
        this.link = res.result.link_address
        this.start = new Date(res.result.start_time).getTime()
        this.end = new Date(res.result.end_time).getTime()
        this.getShowRules()
      })
    },
    getShowRules() {
      const inapp = tool.isInApp()
      const now = Date.parse(new Date())
      const last = !Cookies.get('popup_timestamp_cookies_m')
        ? 0
        : parseInt(Cookies.get('popup_timestamp_cookies_m'))
      if (now > this.start && now < this.end && now - last > 3600000 && !inapp) {
        this.isShow = true
        Cookies.set('popup_timestamp_cookies_m', now, { expires: 1 })
      }
    },
    goActive() {
      window.location.href = this.link
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999999;

  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    .img_box {
      width: 600px;
      // height: 200px;
      // background-color: #fff;
      img {
        display: block;
        width: 100%;
        cursor: pointer;
      }
    }
    .close {
      width: 60px;
      margin: 0 auto;
      margin-top: 10px;
      img {
        width: 100%;
        display: block;
        cursor: pointer;
      }
    }
  }
}
</style>
