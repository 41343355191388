import request from '@/utils/request'

// 筑龙首页
export function getIndex(params) {
  return request({
    url: 'index/index/getIndex',
    method: 'get',
    params
  })
}

// 筑龙首页SEO
export function getRow(params) {
  return request({
    url: '/setting/seo/getRow',
    method: 'get',
    params
  })
}

// seo
export function getRowSeo(params) {
  return request({
    url: '/setting/seo/getRow',
    method: 'get',
    params
  })
}
