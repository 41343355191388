import { getRowSeo } from '@/api/index/index'
const apis = {
  getSeoMsg(type) {
    var params = {
      type
    }
    getRowSeo(params).then(res => {
      console.log(1)
      // 设置title标题
      const my_title = document.getElementsByTagName('title')[0]
      my_title.innerHTML = res.result.result.title
      // 设置关键词
      const my_keys = document.getElementsByName('keywords')[0]
      my_keys.content = res.result.result.words
      // 设置描述
      const my_description = document.getElementsByName('description')[0]
      my_description.content = res.result.result.description
    })
  }
}
export default apis
